<template>
  <transition>
    <div
      v-show="searchNavVisibility"
      class="search-form-container hdn"
      id="search-input-container"
    >
      <div class="search-input-group">
        <button
          @click="leaveNavSearch"
          type="button"
          class="btn btn-default"
          id="hide-search-input-container"
        >
          <span class="close-search">x</span>
        </button>
        <div class="form-group">
          <input
            type="text"
            name="name"
            :value="inputValue"
            class="home-search form-control search-prod pr-0"
            placeholder="Cauta in site"
            v-on:keyup="searchOnEnter"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    searchNavVisibility() {
      return this.$store.getters["search/searchNavbar"];
    },
    inputValue() {
      return this.$store.getters["search/inputValue"];
    },
  },

  methods: {
    leaveNavSearch() {
      this.$store.dispatch("search/stateNavbar", false);
    },

    enterNavSearch() {
      const input = document.querySelector(".search-prod");
      // console.log(input);
      if (this.searchNavVisibility && input.value !== "") {
        // console.log(input.value);
        const payload = {
          search: input.value,
        };
        this.search(payload);
      }
      this.$store.dispatch("search/stateNavbar", true);
      // console.log(input);
      input.focus();
    },
    searchOnEnter(e) {
      let payload = {
        search: e.target.value,
      };
      this.$store.dispatch("search/syncSearchInput", payload.search);
      if (e.keyCode === 13) {
        // console.log(e.target.value);

        this.search(payload);
      }
    },
    search(payload) {
      this.$store.dispatch("shop/changeSelFromShop", true).then(() => {
        // this.$store.dispatch('search/search', payload).then(() => {
        this.$router.push({ name: "searchpage", query: { name: payload.search } });
        // })
        // console.log("route", this.$route.params.page);
        if (
          (this.$route.query.name !== undefined &&
            this.$route.query.name !== payload.search) ||
          this.$route.params.page
        ) {
          this.$store.dispatch("search/search", payload);
          this.$store.dispatch("search/actPage", 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.search-form-container {
  text-align: right;
  position: absolute;
  height: 54px;
  overflow: hidden;
  background: #282b2f;
  right: 0;
  z-index: 9;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  width: 100%;
}

.v-enter-from {
  width: 0px;
}

.v-enter-active {
  transition: all 1s ease-out;
}

.v-enter-to {
  width: 100%;
}

.v-leave-from {
  width: 100%;
}

.v-leave-active {
  transition: all 1s ease-in;
}

.v-leave-to {
  width: 0;
}

.search-input-group {
  display: flex;
  align-content: center;
}

.search-form-container .search-input-group {
  padding-left: 20px;
  width: 100%;
}

.search-input-group .btn {
  border: 0;
  background: transparent;
  font-size: 18px;
}

.search-input-group .form-group {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

#hide-search-input-container {
  color: #fff;
  padding-right: 8px;
}

.form-group input {
  border-radius: 0;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px rgb(204, 204, 204);
}

.form-control.search-prod {
  height: 54px;
  border: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: #282b2f;
  color: #fff;
}

.close-search {
  font-size: 16px;
  font-weight: bold;
}

#hide-search-input-container:focus {
  box-shadow: none;
}

.search-input-group .form-group::before {
  content: "";
  width: 1px;
  height: 15px;
  background-color: grey;
  position: absolute;
  top: 21px;
  left: 0;
}

.home-search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  font-size: 12px;
}
.home-search::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  font-size: 12px;
}
.home-search:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  font-size: 12px;
}
.home-search:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  font-size: 12px;
}
</style>
