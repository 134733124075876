<template>
  <li v-if="categ.children?.length">
    <a v-show="visible" href="#" @click="submenu">
      {{ formatCategName }}
    </a>
    <transition name="slide-fade">
      <div v-show="isSubcateg" :id="'menu_categ_' + categ.id" class="menu_categ">
        <ul class="mmenu-subcateg-listview" :id="'categ_' + categ.id">
          <img class="back-categ" src="@/assets/images/menu_arrow.png" @click="backCateg" />
          <li>
            <a href="#" class="subcateg-title">{{ categ.name }}</a>
          </li>
          <MenuMobileItem
            v-for="(subcateg, index) in categ.children"
            :key="index"
            :categ="subcateg"
            :length="+categ.children.length"
          />
        </ul>
        <MenuMobileUsefulLinks />
      </div>
    </transition>
  </li>
  <li v-else @click="close">
    <router-link
      v-if="visible"
      :to="{ name: 'ProductList', params: { slug: categ.slug } }"
    >
      <span class="">{{ formatCategName }}</span>
    </router-link>
  </li>
</template>

<script>
// import MenuMobileItem from '@/components/menu/MenuMobileItem.vue';
import MenuMobileUsefulLinks from '@/components/menu/MenuMobileUsefulLinks.vue';

export default {
  name: "MenuMobileItem",
  data() {
    return {
      isSubcateg: false,
      subcategs: [],
    };
  },
  props: {
    categ: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // MenuMobileItem,
    MenuMobileUsefulLinks,
  },
  // watch: {
  //     isSubcateg() {
  //         console.log(this.isSubcateg);
  //     }
  // },
  computed: {
    formatCategName() {
      let categ_name = this.categ.name.charAt(0) + this.categ.name.slice(1).toLowerCase();
      // if (categ_name.length > 30) {
      //     return categ_name.substring(0,30) + '...';
      // }
      return categ_name;
    },
  },
  methods: {
    close() {
      this.$store.dispatch("menu/closeNav");
    },
    submenu() {
      this.isSubcateg = true;
      
      document.getElementById("menu_categ_" + this.categ.id).style.visibility = "visible";

      var navbar = document.getElementById("mobile-nav");
      navbar.style.overflow = 'hidden';
      setTimeout(() => {
        navbar.style.visibility = 'hidden';
      }, 500);

      // console.log(document.querySelectorAll("#categ_" + this.categ.id + " li"));
      // document.getElementById("categ_" + this.categ.id).scrollIntoView(true);
      // location.href = "#categ_" + this.categ.id;

      // this.setMenuHeight();
    },
    backCateg() {
      // console.log('test');
      this.isSubcateg = false;
      var navbar = document.getElementById("mobile-nav");
      navbar.style.visibility = 'visible';
      navbar.style.overflow = 'scroll';
      // this.setMenuHeight();
    },
    setMenuHeight() {
      const menuHeight = document.querySelector(".mmenu-listview");
      const currentMenuHeight = document.getElementById("categ_" + this.categ.id);
      console.log(currentMenuHeight);

      setTimeout(function () {
        // console.log(menuHeight.offsetHeight);
        menuHeight.style.height = currentMenuHeight.offsetHeight + "px";
        // console.log(menuHeight.offsetHeight);
      });
    },
  },
  // updated() {
  //   this.setMenuHeight();
  // },
};
</script>

<style scoped>
.menu_categ {
  visibility: hidden;
  position: absolute; 
  top: 0;
  z-index: 10; 
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 10s linear;
}
.show-enter,
.show-leave-to {
  transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s linear;
}

.slide-fade-leave-active {
  transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
  /* opacity: 0; */
}
li > a,
li > span {
  color: #2e2e30;
  padding: 7px 18px 7px 18px;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  display: block;
  margin: 0;
  font-size: 16px;
  font-family: Raleway Semibold;
  border-bottom: solid 1px #e8e6e6;
}

li > a {
  display: flex;
  align-items: center;
  height: 100%;
}

li > span {
  order: 0;
}


.subcateg-title {
  font-family: Raleway Bold;
  font-size: 17px;
  color: #000;
  justify-content: left;
  padding-left: 55px;
  padding-right: 10px;
  height: 71px;
  line-height: 1;
  border-bottom: unset;
}

ul.mmenu-subcateg-listview {
  position: relative;
  background: #fff;
  width: 100%;
  list-style: none;
  /* padding-top: 15px; */
  /* overflow: auto; */
  padding-left: 0;
  min-height: calc(100% - 350px);
}

.back-categ {
  position: absolute;
  left: 15px;
  top: 28px;
  z-index: 12;
  cursor: pointer;
}

@media (min-width: 576px) {
  ul.mmenu-subcateg-listview {
    min-height: calc(100% - 290px);
  }

  .useful-links-wrapper {
    height: 290px;
  }
}
</style>
