<template>
   <VueFinalModal 
      v-model="computedShowPhoneModal"
      class="phone-modal"
      content-class="phone-modal-content"
   >
      <div class="phone-modal-header">
         <button class="btn btn-close close-modal float-right" @click="computedShowPhoneModal = false">
            <img src="@/assets/images/x-close.svg" />
         </button>
      </div>
      <div class="phone-modal-body">
         <div class="phone-title">
            Apelează
         </div>
         <hr class="d-none d-md-block">
         <div class="phoneLinkWrapper">
            <a class="phoneLink" href="tel:0377101500">
               <div class="phoneItem">
                  <span class="iconWrp"><img width="23" height="23" class="fa-phone" src="@/assets/images/phone_mobile.svg" alt="phone" /></span>
                  <span class="iconTitle">Departament termic</span>
               </div>
               <div class="d-none d-md-block phoneNumber">0377 101 500</div>
            </a>
         </div>
         <hr class="d-none d-md-block">
         <div class="phoneLinkWrapper">
            <a class="phoneLink" href="tel:0377101544">
               <div class="phoneItem">
                  <span class="iconWrp"><img width="23" height="23" class="fa-phone" src="@/assets/images/phone_mobile.svg" alt="phone" /></span>
                  <span class="iconTitle">Departament grătare & exterior</span>
               </div>
               <div class="d-none d-md-block phoneNumber">0377 101 544</div>
            </a>
         </div>
         <hr class="d-none d-md-block">
      </div>
   </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';

export default {
  components: {
    VueFinalModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    computedShowPhoneModal: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
};
</script>

<style>
.phone-modal .phone-modal-content {
  border-radius: 33px 0 0 33px;
  background: #fff;
  width: 540px;
  height: 100%;
  float: right;
  position: relative;
  padding: 40px;
}

.phone-modal .phone-modal-header {
   margin-bottom: 80px;
}

.phone-modal .phone-title {
   font-family: 'Raleway Bold';
   font-size: 32px;
   margin-bottom: 40px;
}

.phone-modal .phoneLink {
   color: #000;
   display: block;
}

.phone-modal .phoneItem {
   transition: all 0.1s ease-in;
   background-color: #e8e8e8;
   padding: 14px;
   margin-top: 10px;
   border-radius: 30px;
   display: inline-block;
   position: relative;
}

.phone-modal .phoneNumber {
   font-family: 'Montserrat Bold';
   font-size: 16px;
   color: #383838;
   padding-top: 25px;
   margin-bottom: 25px;
}

.phone-modal .phoneItem .iconWrp {
   background-color: #4fb01c;
   padding: 14px;
   border-radius: 36px;
   position: absolute;
   left: -5px;
   top: 0;
}

.phone-modal .phoneItem .iconTitle {
   font-family: 'Montserrat Bold';
   font-size: 16px;
   margin-left: 50px;
   margin-right: 10px;
}

.phone-modal .btn.close-modal:focus {
   box-shadow: none;
}

@media (min-width: 768px) {
   .phone-modal .phoneLink:hover {
      color: #fff;
   }
   
   .phone-modal .phoneLink:hover .phoneItem {
      transition: all 0.1s ease-in;
      background-color: #454545;
   }
}

@media (max-width: 768px) {
   .phone-modal .phone-modal-content {
      width: 100%;
      border-radius: 0;
      text-align: center;
   }

   .phone-modal .phoneLink {
      display: inline-block;
      width: 100%;
      color: #fff;
      background-color: #4fb01c;
      margin-bottom: 30px;
      border-radius: 30px;
   }
   .phone-modal .phoneLink > * {
      background-color: #4fb01c;
   }

   .phone-modal .phoneItem, .phone-modal .phoneItem .iconTitle { 
      font-size: 14px;
      margin: 0;
   }

   .phone-modal .phone-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin-top: -80px;
   }

   .phone-modal .phoneItem .iconWrp {
      position: relative;
      padding: 5px;
   }
}
</style>