<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen">
        <div class="sidebar-panel" id="sidebar-panel">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
// import MenuLinkItem from "@/components/menu/MenuLinkItem.vue";

export default {
  methods: {
    closeSidebarPanel() {
      this.$store.dispatch("menu/toggleMenuNav");
    },
  },

  data() {
    return {
      links: [
        {
          name: "Video",
          route: "Videos",
        },
        {
          name: "Modele",
          route: "Modele",
        },
        {
          name: "Blog",
          route: "Blog",
        },
        {
          name: "Despre noi",
          route: "Despre Noi",
        },
        {
          name: "Contact",
          route: "contact",
        },
      ],
    };
  },

  // components: {
  //   MenuLinkItem,
  // },

  computed: {
    isPanelOpen() {
      return this.$store.getters["menu/navOpen"];
    },

    categories() {
      return this.$store.getters["shop/categories"];
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  /* overflow-y: auto; */
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 100px;
  height: calc(100vh - 100px);
  z-index: 999;
  /* padding: 3rem 20px 2rem 20px; */
  width: 80%;
  overflow: overlay;
}

#sidebar-panel::-webkit-scrollbar {
  width: 17px;
}

#sidebar-panel::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #97040c;
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.mmenu-listview,
.mmenu-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mmenu-listview {
  padding-top: 10px;
}


@media (max-width: 576px) {
  .sidebar-panel {
    top: 60px;
    height: 100%;
  }
}


</style>
