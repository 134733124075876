<template>
  <div class="col-md-8 col-lg-9 col-xl-7 search-wrapper" v-show="searchNavVisibility">
    <div class="navbar-form navbar-form-search header-search">
      <input
        type="text"
        name="name"
        :value="inputValue"
        class="search_submit search-input d_xs_none"
        placeholder="Cauta in site..."
        tabindex="-1"
        v-on:keyup="searchOnEnter"
      />
      <img v-if="showSearchBtn"
        src="@/assets/images/search_icon.webp"
        alt="search mobile icon"
        @click="searchOnTouch"
        loading="lazy"
      />
      <font-awesome-icon v-if="!showSearchBtn" @click="removeInputValue" icon="times" class="remove-input-search" />

    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      image: "/themes/pefoc-vuetober/src/assets/images/search_icon.webp",
      searchNavVisibility: this.isVisible,
      showSearchBtn: true,
    };
  },

  computed: {
    searchInput() {
      return document.querySelector(".search-input");
    },
    inputValue() {
      return this.$store.getters["search/inputValue"];
    },
  },

  methods: {
    enterNavSearch() {
      this.searchNavVisibility = !this.searchNavVisibility;
    },
    removeInputValue() {
      this.$store.dispatch("search/syncSearchInput", "");
      this.showSearchBtn = true;
    },
    closeInputSearch() {
      this.searchNavVisibility = false;
    },
    searchOnEnter(e) {
      let payload = {
        search: e.target.value,
      };
      this.$store.dispatch("search/syncSearchInput", payload.search);
      if (e.keyCode === 13) {
        // console.log(e.target.value);

        this.search(payload);
        this.searchNavVisibility = false;
        this.showSearchBtn = false;
      }
    },
    searchOnTouch() {
      let payload = {
        search: this.searchInput.value,
      };
      this.search(payload);
      this.searchNavVisibility = false;
      this.showSearchBtn = false;
    },
    search(payload) {
      this.$store.dispatch("shop/changeSelFromShop", true).then(() => {
        if (this.$route.name === "searchpage") {
          this.$store.dispatch("search/search", payload);
          this.$store.dispatch("search/actPage", 1);
        }
        // else {
        this.$router.push({ name: "searchpage", query: { name: payload.search } });
        // }
      });
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  padding: 0 15px;
}

.header-search img {
  display: none;
}

.search-input {
  background-repeat: no-repeat;
  background-position: 95% 10px;
  background-size: 18px;
}

.search-wrapper input {
  width: 100%;
  padding: 0px 20px;
  font-family: Montserrat Medium;
  font-size: 12px;
  height: 30px;
  border-radius: 17px;
}

.navbar-form-search {
  position: relative;
}

@media (min-width: 980px) {
  .search-wrapper {
    padding: 0 25px 0 15px;
  }
}

.mobile-navbar-input-search {
  position: absolute;
  bottom: -65px;
  padding: 0;
}

.mobile-navbar-input-search form {
  padding: 5px;
  background: #000;
  margin: 10px 0px;
  position: relative;
  margin: 0;
}

.mobile-navbar-input-search form input {
  height: 40px;
  background-position: 97% 10px;
}

.remove-input-search {
  position: absolute;
  font-size: 20px;
  right: 12px;
  top: 5px;
}

@media (max-width: 768px) {
  .search-wrapper input {
    height: 40px;
    border: 0;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-form-search {
    padding: 13px 20px;
    background-color: #2e2e30;
  }

  .header-search img {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translate(0%, -50%);
    display: block;
  }

  .remove-input-search {
    right: 40px;
    top: 23px;
  }
}
</style>
