<template>
    <div class="d-lg-block footer-block-content">
        <div class="payment-methods">
            <div class="footer-title">Modalități de plată</div>
            <img width="49" height="30" src="@/assets/footer/visa.svg" alt="Visa" />
            <img width="49" height="30" src="@/assets/footer/maestro.svg" alt="Maestro" />
            <img width="46" height="30" src="@/assets/footer/visa-electron.svg" alt="Visa Electron" />
            <img width="49" height="30" src="@/assets/footer/mastercard.svg" alt="Mastercard" />
            <img width="49" height="30" src="@/assets/footer/netopia.svg" alt="Netopia" />
            <img width="30" height="30" src="@/assets/footer/bitcoin.svg" alt="Bitcoin" />
            <img width="30" height="30" src="@/assets/footer/ethereum.svg" alt="Ethereum" />
            <img width="30" height="30" src="@/assets/footer/utrust.svg" alt="Utrust" />
        </div>
        <div class="quality-services">
            <button type="button" @click="goToReview">
                <div class="footer-title">
                    Produse și servicii de 5 stele 
                </div>
                <div class="stars">
                    <img width="94" height="16" loading="lazy" src="@/assets/footer/5stars.svg" alt="5 stele" />
                </div>
            </button>
        </div>
        <div class="anpc">
            <a
                class="d-inline-block"
                href="https://anpc.ro/ce-este-sal/"
                title=""
                target="_blank"
                rel="noopener noreferrer nofollow"
            >
                <img
                    width="250"
                    height="50"
                    loading="lazy"
                    src="/themes/pefoc-vuetober/public/anpc-sal.webp"
                    alt="Solutionarea alternativa a litigiilor"
                />
            </a>
            <a
                class="d-inline-block"
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=RO"
                title=""
                target="_blank"
                rel="noopener noreferrer nofollow"
            >
                <img
                    width="250"
                    height="50"
                    loading="lazy"
                    src="/themes/pefoc-vuetober/public/anpc-sol.webp"
                    alt="Solutionarea oline a litigilor"
                />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardsBlock",
    methods: {
        goToReview() {
            if(this.$route.name == "Home") {
                var reviewstars = document.getElementById("reviewstars");
                reviewstars.scrollIntoView({ behavior: "smooth", block: "center"});
            } else {
                document.cookie = "scrollToReview=1";
                this.$router.push({
                    path: '/', 
                    // hash: '#reviewstars'
                });
            }
        },
    },
};
</script>
