<template>
  <nav id="mobile-nav" class="mobile-nav">
    <div class="mmenu-container vld-parent" id="mm-1">
      <ul v-if="categories" class="mmenu-listview">
        <loading :active="loadCategs" :is-full-page="false"/>
        <li class="categs-title">Categorii produse</li>
        <MenuMobileItem
          v-for="(categ, index) in categories"
          :key="index"
          :categ="categ"
        />
      </ul>
      <MenuMobileUsefulLinks />
    </div>
  </nav>
</template>

<script>
import MenuMobileItem from "@/components/menu/MenuMobileItem.vue";
// import MenuLinkItem from "@/components/menu/MenuLinkItem.vue";
import MenuMobileUsefulLinks from "@/components/menu/MenuMobileUsefulLinks.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["categories"],
  components: {
    MenuMobileItem,
    Loading,
    // MenuLinkItem,
    MenuMobileUsefulLinks,
  },

  data() {
    return {
      isVisible: true,
    };
  },

  computed: {
    loadCategs() {
      return this.$store.getters["shop/loadCategs"];
    },
  },

  methods: {
    iconSrc() {
      const categ_id_two = 2;
      // console.log(this.categories);
      return require(`@/assets/images/mmenu/categ_${categ_id_two}.png`);
    },
    close() {
      this.$store.dispatch("menu/toggleMenuNav");
    },
  },
  mounted() {
    location.href = "#";
    // console.log(this.categories);
    // if (this.categories.length) {
    //   const deep1 = this.categories.map((categ) => {
    //     let newCateg = {
    //       name: categ.name,
    //       slug: categ.slug,
    //     };
    //     return newCateg;
    //   });
    // console.log(deep1);
    // }
  },
};
</script>

<style scoped>
.mmenu-listview,
.mmenu-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mmenu-listview {
  padding-top: 10px;
  min-height: 500px;
}

.mmenu-listview li.categs-title {
  font-family: Raleway Bold;
  font-size: 22px;
  padding: 34px 18px;
  display: flex;
  align-items: center;
  border-bottom: unset;
}
.mmenu-listview li {
  height: 43px;
  border-bottom: solid 1px #e8e6e6;
}

.sticky-menu-categs {
  width: 300px;
  position: absolute;
}
.mobile-nav {
  height: 100%;
}
.mmenu-container {
  height: 100%;
  position: unset !important;
}
.vld-overlay {
  /* height: calc(100% - 350px); */
}

.sidebar .social-links {
  padding-left: 18px;
  line-height: 60px;
  text-align: left;
}

.sidebar .social-links a {
  padding-right: 10px;
}
</style>
