<template>
    <div class="useful-links-wrapper" id="mm-2">
      <ul class="mmenu-useful-links">
        <MenuLinkItem v-for="(link, index) in links" :key="index" :link="link" />
      </ul>
      <div class="social-links">
        <a target="_blank" href="https://www.youtube.com/c/PefocRo-seminee-pe-lemne-moderne-la-preturi-mici"><img src="@/assets/images/youtube.svg" alt="youtube"></a>
        <a target="_blank" href="https://www.facebook.com/pefoc.ro"><img src="@/assets/images/facebook.svg" alt="facebook"></a>
        <a target="_blank" href="https://www.instagram.com/pefoc.ro"><img src="@/assets/images/instagram.svg" alt="instagram"></a>
        <a target="_blank" href="https://www.pinterest.com/pefoc_ro/"><img src="@/assets/images/pinterest.svg" alt="pinterest"></a>
        <a target="_blank" href="https://www.tiktok.com/@pefoc.ro"><img src="@/assets/images/tiktok.svg" alt="tiktok"></a>
        <a target="_blank" href="https://www.linkedin.com/company/pefoc"><img src="@/assets/images/linkedin.svg" alt="linkedin"></a>
      </div>
    </div>
</template>

<script>
import MenuLinkItem from "@/components/menu/MenuLinkItem.vue";

export default {
  components: {
    MenuLinkItem,
  },

  data() {
    return {
      links: [
        {
          name: "Outlet",
          route: "ProductList",
          param: "depozit-outlet-magazin-online-romania_465",
        },
        {
          name: "Video",
          route: "Videos",
        },
        {
          name: "Modele",
          route: "Modele",
        },
        {
          name: "Blog",
          route: "Blog",
        },
        {
          name: "Contact",
          route: "contact",
        },
      ],
    };
  },
};
</script>

<style scoped>
.useful-links-wrapper {
  background: #1d1d1d;
  color: #fff;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.useful-links-wrapper a span {
  color: #fff;
}

.mmenu-useful-links,
.mmenu-useful-links > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mmenu-useful-links {
  padding-top: 10px;
  /* height: calc(100% - 350px); */
  overflow-y: auto;
}

.sidebar .social-links {
  padding-left: 18px;
  line-height: 60px;
  text-align: left;
}

.sidebar .social-links a {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .useful-links-wrapper {
    background: #f4f4f4;
  }
}

@media (max-width: 576px) {
  .useful-links-wrapper {
    height: 350px;
  }
}
</style>
