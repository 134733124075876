<template>
  <div
    class="social-block"
  >
    <link itemprop="url" href="https://pefoc.ro" />
    <div class="footer-title">Urmărește-ne</div>
    <div class="sqare-wrp social">
      <link href="https://pefoc.ro" />
      <!-- <?php if($this->config->item('youtube')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.youtube.com/c/PefocRo-seminee-pe-lemne-moderne-la-preturi-mici"
          target="_blank"
          title="Youtube Pefoc.ro"
          >
            <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/youtube-social.png" 
              alt="youtube"
            />
        </a>
      </div>
      <!-- <?php endif; ?> -->
      <!-- <?php if($this->config->item('facebook')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.facebook.com/pefoc.ro/"
          target="_blank"
          title="Facebook Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'facebook']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/facebook-social.png" 
              alt="facebook"
            />
        </a>
      </div>
      <!-- <?php endif; ?> -->
      <!-- <?php if($this->config->item('gplus')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.instagram.com/pefoc.ro/?hl=en"
          target="_blank"
          title="Instagram Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'instagram']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/instagram-social.png" 
              alt="instagram"
            />
        </a>
      </div>
      <!-- <?php endif; ?> -->
      <!-- <?php if($this->config->item('pinterest')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.pinterest.com/semineepelemne/seminee-pe-lemne/"
          target="_blank"
          title="Pinterest Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'pinterest']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/pinterest-social.png" 
              alt="pinterest"
            />
        </a>
      </div>
      <!-- <?php endif; ?> -->
      <!-- <?php if($this->config->item('pinterest')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.tiktok.com/@pefoc.ro"
          target="_blank"
          title="TikTok Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'pinterest']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/tiktok-social.png" 
              alt="tiktok"
            />
        </a>
      </div>
      <!-- <?php endif; ?> -->
      <!-- <?php if($this->config->item('pinterest')): ?> -->
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://www.linkedin.com/company/pefoc"
          target="_blank"
          title="LinkedIn Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'pinterest']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/linkedin-social.png" 
              alt="linkedin"
            />
        </a>
      </div>
      <!-- <?php endif; ?> --> 
      <div class="sqare">
        <a
          itemprop="sameAs"
          href="https://twitter.com/pefoc_seminee"
          target="_blank"
          title="Twitter Pefoc.ro"
        >
          <!-- <font-awesome-icon :icon="['fab', 'pinterest']" /> -->
          <img 
              width="28"
              height="28"
              loading="lazy" 
              src="@/assets/footer/twitter-social.png" 
              alt="twitter"
            />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialBlock",
};
</script>

<style scoped>
.social svg {
  border-radius: 5px;
  vertical-align: middle;
}

.sqare svg {
  padding-top: calc(50% - 15px);
}
</style>
