<template>
    <header
        itemscope="itemscope"
        itemtype="http://schema.org/WPHeader"
        class="header container-fluid header-pefoc"
    >
        <span itemprop="name" class="schemaname">PeFoc</span>
        <nav class="container navbar navbar-expand-md navbar-dark navbar-pf">
            <router-link class="navbar-brand mr-0" to="/" aria-label="Go to main page">
                <img
                    loading="lazy"
                    itemprop="image"
                    src="@/assets/images/logo.webp"
                    alt=""
                    :class="{ productpagelogo: this.$route.path.split('/')[1] === 'produs' }"
                />
            </router-link>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse navbar-pf" id="navbarSupportedContent">
                <ul
                    itemscope
                    itemtype="https://schema.org/SiteNavigationElement"
                    class="navbar-nav navbar-links col-12 col-lg-auto col-md-auto mr-0 mb-3 mb-lg-0 mb-md-0 pr-0 pl-0"
                >
                    <li
                        class="nav-item"
                        :class="{ active: this.$route.path.split('/')[1] === 'produse' }"
                    >
                        <a
                            itemprop="url"
                            class="nav-link"
                            id="navbarDropdown-1"
                            role="button"
                            data-toggle="dropdown1"
                            aria-haspopup="true"
                            aria-expanded="false"
                            @click="goto()"
                        >
                            <span itemprop="name">PRODUSE</span>
                        </a>
                    </li>
                    <li
                        class="nav-item"
                        :class="{ active: this.$route.path === '/video' }"
                    >
                        <router-link
                            itemprop="url"
                            class="nav-link"
                            to="/video"
                            id="navbarDropdown-2"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span itemprop="name">VIDEO</span></router-link
                        >
                    </li>
                    <li
                        class="nav-item"
                        :class="{
                            active: this.$route.path === '/modele-seminee/page/all',
                        }"
                    >
                        <router-link
                            itemprop="url"
                            class="nav-link"
                            :to="{ name: 'Modele' }"
                            id="navbarDropdown-3"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span itemprop="name">MODELE</span></router-link
                        >
                    </li>
                    <li
                        class="nav-item"
                        :class="{
                            active:
                                this.$route.path ===
                                '/produse/depozit-outlet-magazin-online-romania_465',
                        }"
                    >
                        <router-link
                            itemprop="url"
                            to="/produse/depozit-outlet-magazin-online-romania_465"
                            class="nav-link"
                            id="navbarDropdown-4"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span itemprop="name">OUTLET</span></router-link
                        >
                    </li>
                    <li class="nav-item" :class="{ active: isBlog === 'blog-seminee' }">
                        <router-link
                            itemprop="url"
                            class="nav-link"
                            to="/blog-seminee"
                            id="navbarDropdown-5"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span itemprop="name">BLOG</span></router-link
                        >
                    </li>
                    <li
                        class="nav-item"
                        :class="{ active: this.$route.path === '/contact' }"
                    >
                        <router-link
                            itemprop="url"
                            to="/contact"
                            class="nav-link"
                            href="/contact"
                            id="navbarDropdown-6"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span itemprop="name">CONTACT</span></router-link
                        >
                    </li>
                    <li class="nav-item">
                        <router-link
                            itemprop="url"
                            class="nav-link reduceri-link"
                            to="/promotie-seminee-promotii"
                            ><span itemprop="name">OFERTE</span></router-link
                        >
                    </li>
                    <input-search-nav ref="childcomponent"></input-search-nav>
                </ul>

                <div class="search search_btn header__square-pf header-left__common">
                    <button class="footer__link" @click="searchBtnAction">
                        <img
                            width="23"
                            height="23"
                            src="@/assets/images/search_mobile.svg"
                            alt="Search"
                        />
                    </button>
                </div>

                <div class="phone header__square-pf header-left__common">
                    <a class="link" @click="showPhoneModal = true">
                        <img width="23" height="23" class="fa-phone" src="@/assets/images/phone_mobile.svg" alt="Call" />
                    </a>
                </div>
                <div
                    class="_ajax_cart_link_header_wrapper header__square-pf header-left__common col-12 col-lg-auto col-md-auto mb-md-0 mb-4 mb-lg-0"
                >
                    <mini-cart-card></mini-cart-card>
                </div>
            </div>
        </nav>
        <phone-modal :show="showPhoneModal" @close="showPhoneModal = false"></phone-modal>

        <sticky-navbar v-if="scrollNavbar"></sticky-navbar>
        <mobile-navbar></mobile-navbar>
    </header>
</template>

<script>
import MiniCartCard from "@/components/header/minicart/MiniCartCard.vue";
import InputSearchNav from "@/components/header/InputSearchNav.vue";
import PhoneModal from "@/components/header/PhoneModal.vue";
import MobileNavbar from "@/components/header/mobile/MobileNavbar.vue";
import { generalMixin } from "@/mixins/generalMixin";
import { defineAsyncComponent } from "vue";

export default {
    components: {
        MiniCartCard,
        InputSearchNav,
        // PhoneNavbar,
        StickyNavbar: defineAsyncComponent(() =>
        import(
                /* webpackChunkName: "stickynavbar" */ "@/components/header/StickyNavbar.vue"
            )
        ),
        MobileNavbar,
        PhoneModal,
    },

    data() {
        return {
            searchNavVisibility: false,
            scrollNavbar: false,
            isHome: true,
            showPhoneModal: false,
        };
    },

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (route?.name === "Home") {
                    this.isHome = true;
                } else {
                    this.isHome = false;
                }
            },
        },
    },

    computed: {
        isBlog() {
            return this.$route.path.split("/")[1];
        },
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },

    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    mixins: [generalMixin],
    methods: {
        searchBtnAction() {
            this.$refs.childcomponent.enterNavSearch();
        },

        handleScroll() {
            // console.log(window.scrollY);
            if (window.scrollY > 260) {
                this.scrollNavbar = true;
            } else {
                this.scrollNavbar = false;
            }
        },
        goto() {
            var element = document.getElementById("produse");
            if (element) {
                var headerOffset = 40;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            } else {
                this.setScrollTo();
            }
        },
    },
};
</script>

<style>
.schemaname {
    display: none;
}
@import "../../assets/css/header.css";

.navbar-pf ul li.nav-item.active > a.nav-link {
    /* background: rgba(157,0,0,0.8); */
    color: #c0050f !important;
}

.navbar-pf ul li.nav-item.active > a.nav-link:hover {
    /* background-color: rgba(157,0,0,0.8); */
    background-color: #97040c;
    color: #fff !important;
}

.header-pefoc .productpagelogo {
    width: 157px !important;
    height: 157px !important;
} 

.header-pefoc a.link {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .header-pefoc .reduceri-link:after {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .navbar-pf {
        display: none;
    }
}
</style>
