<template>
  <li @click="closeSideBar">
    <router-link :to="{ name: link.route, params: { slug: link.param} }">
      <span class="">{{ link.name }}</span>
    </router-link>
  </li>
</template>

<script>
// import MenuMobileItem from '@/components/menu/MenuMobileItem.vue';

export default {
  name: "MenuMobileItem",
  props: ["link"],
  methods: {
    closeSideBar() {
      this.$store.dispatch("menu/closeNav");
    },
  },
};
</script>

<style scoped>
li > a,
li > span {
  color: #000;
  padding: 6.5px 10px 6.5px 18px;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  display: block;
  margin: 0;
  font-size: 20px;
  /* border-bottom: solid 1px #f5f6f5; */
  font-family: Raleway Bold;
  /* text-transform: uppercase; */
}

li > a {
  display: flex;
  align-items: center;
  height: 100%;
}

li > span {
  order: 0;
}

@media (max-height: 915px) {
  li {
    /* height: 32px; */
  }
}

.subcateg-title {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

ul.mmenu-subcateg-listview {
  position: absolute;
  background: #f5f6f5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  list-style: none;
  padding-top: 15px;
}

.back-categ {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 12;
  cursor: pointer;
}
</style>
