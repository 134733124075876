<template>
    <div class="footer-block-content links_wrp">
        <div class="footer-title">Informații</div>
        <div class="terms-item cum-comand">
            <router-link to="/cum-cumpar.html">Cum comand?</router-link>
        </div>
        <div class="terms-item">
            <router-link to="/conditii-de-transport.html"
                >Conditii de transport</router-link
            >
        </div>
        <div class="terms-item">
            <router-link to="/producatori-seminee-pefoc-romania">Parteneri</router-link>
        </div>
        <div class="terms-item">
            <router-link to="/vanzare-seminee-firme-executie-mesteri-sobari">Acoperire Nationala</router-link>
        </div>
        <div class="terms-item">
            <router-link to="/privacy-and-cookies.html">Privacy & Cookies</router-link>
        </div>
        <div class="terms-item">
            <router-link to="/politica-de-confidentialitate-a-datelor.html"
                >Politica de confidentialitate</router-link
            >
        </div>
        <div class="terms-item">
            <router-link to="/termeni-si-conditii.html">Termeni si conditii</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "InformationsBlock",
};
</script>

<style scoped>
.terms-item a:hover {
    color: #fff;
}
</style>
