<template>
<div class="rights-block">
    <div class="rights-img">
        <img
            id="one"
            width="66"
            height="66"
            loading="lazy"
            src="@/assets/footer/logo_small_white.png"
            alt="Pefoc.ro"
        />
    </div>
    <div class="rights-info">
        <div class="address">CUI: RO 19110365</div>
        <div class="address_ex">Reg.Com/J : J32/1494/2006</div>
        <div class="copyright">&copy; PEFOC.RO</div>
    </div>
</div>
</template>

<script>
export default {
    name: "RightsBlock",
};
</script>
